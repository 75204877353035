<template>
  <div class="sonContent">
    <!--数据表格-->
    <el-table
      ref="tableData"
      :data="tableData"
      style="width: 100%; margin: 15px 0"
      border
       height="calc(100vh - 328px)"
      :stripe="true"
    >
      <el-table-column type="index" label="序号" width="70" align="center">
      </el-table-column>
      <el-table-column
        prop="projectNumber"
        label="工程编号"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="projectName"
        label="工程名称"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="constructionTeam"
        label="第三方施工队"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="siteManager"
        label="现场管理员"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createBy"
        label="提交人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="提交时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="hanleBtn == 5"
        prop="returnMode"
        label="退料方式"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
              v-if="hanleBtn!=2"
        prop="pcObtainBy"
        label="确认人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
              v-if="hanleBtn!=2"
        prop="pcObtainTime"
        label="确认时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column width="220" label="操作" align="left">
        <template slot-scope="{ row }">
          <el-button
            v-if="hanleBtn == 1"
            type="success"
            size="small"
            @click="turnMenu(row, 4)"
            >审核提交</el-button
          >
          <el-button
            v-if="hanleBtn == 2"
            type="success"
            size="small"
            @click="twoturnMenu(row, 4)"
            >审核提交</el-button
          >
          <el-button
            v-if="hanleBtn == 3"
            type="success"
            size="small"
            @click="turnMenu(row, 4)"
            >审核</el-button
          >
          <el-button
            v-if="hanleBtn == 3"
            type="primary"
            size="small"
            @click="turnMenu(row, 4)"
            >查看详情</el-button
          >
          <el-button
            v-if="hanleBtn == 5"
            type="primary"
            size="small"
            @click="returnApplyInfo(row)"
            >查看详情</el-button
          >
          <el-button
            v-if="hanleBtn == 5"
            type="success"
            size="small"
            @click="turnMenu(row, 4)"
            >{{
              row.returnMode == "调拨退料" ? "同意调拨" : "同意退料"
            }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
        :current-page="searchBox.current"
        :background="true"
        :page-sizes="[ 30, 50, 100]"
        :page-size="searchBox.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        @close="closeFrom('form')">
      <el-form ref="form" :model="form" label-width="150px" :rules="rule">
        <el-form-item label="是否需要退料" prop="pcCheckMaterial">
          <el-select v-model="form.pcCheckMaterial" style="width:100px;margin:0 10px" placeholder="请选择" size="small">
            <el-option
                v-for="item in chargeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="附加描述" >
          <el-input type="textarea" v-model="form.pcCheckDescribe" maxlength="100"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sumit('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="提示"
        :visible.sync="twodialogVisible"
        :close-on-click-modal="false"
        width="30%"
        @close="closetwoFrom('twoform')">
      <el-form ref="twoform" :model="twoform" label-width="150px" :rules="rule">
        <el-form-item label="成本" prop="pcAuditCost">
          <el-input type="number" v-model="twoform.pcAuditCost" oninput="
      if(value.length>17)value=value.slice(0,17)
     if (isNaN(value)) { value = null }
     if (value.charAt(0) === '0' && value.charAt(1) !== '' && value.charAt(1) !== '.') {
     value = '0' }
     if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 3)}"></el-input>
        </el-form-item>
        <el-form-item label="利润" prop="pcAuditProfit">
          <el-input type="number" v-model="twoform.pcAuditProfit" oninput="
          if(value.length>17)value=value.slice(0,17)
     if (isNaN(value)) { value = null }
     if (value.charAt(0) === '0' && value.charAt(1) !== '' && value.charAt(1) !== '.') {
     value = '0' }
     if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 3)}"></el-input>
        </el-form-item>
        <el-form-item label="项目总工程款" prop="pcAuditFunds">
          <el-input type="number" v-model="twoform.pcAuditFunds" oninput="
      if(value.length>17)value=value.slice(0,17)
     if (isNaN(value)) { value = null }
     if (value.charAt(0) === '0' && value.charAt(1) !== '' && value.charAt(1) !== '.') {
     value = '0' }
     if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 3)}"></el-input>
        </el-form-item>
        <el-form-item label="领导签字审批资料" class="requireTitle">
          <el-upload
                  action="#"
                  list-type="picture-card"
                  :http-request="onFileList"
                  :on-remove="onDeleteFileList"
                  :auto-upload="true"
                  :file-list="fileList"
                   accept=".jpg,.jpeg,.png,.JPG,.JPEG"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="twodialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sumittwoForm('twoform')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import axios from "@/apis/designApi";
  import {storeClerkList,countsSeriesList,updateType} from "../../../RequestPort/cost/cost";
export default {
  name: "dataTabel",
  props: {
    hanleBtn: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: false,
      twodialogVisible: false,
      detildialogVisible: false,
      project: {},
      searchBox: {
        current: 1,
        size: 100,
      },
      chargeList: [
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
      FileFormData: new FormData(),
      FilefileList: [],
      designFileUrl: [],
      fileList: [],
      form: {
        remarks: "",
        billUrl: "",
        id: null,
      },
      twoform:{
        pcAuditFunds:'',
        pcAuditCost:'',
        pcAuditProfit:'',
      },
      rule:{
        pcCheckMaterial: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        pcAuditCost: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        pcAuditProfit: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        pcAuditFunds: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        pcCheckDescribe: [
          { required: true, message: '请输入附加描述', trigger: 'blur' }
        ],
      },
      total:null,
      tableData:[]
    }
  },
  mounted() {
    if(this.hanleBtn==1){
      this.loadList()//仓管审核
    }else {
      this.loadListtwo()//计通审核
    }
  },
  methods:{
    //  上传文件
    async onFileList(files) {
      try {
        let uploadObj = new FormData();
        uploadObj.append("file", files.file);
        let res = await axios.uploadImg(uploadObj);
        this.designFileUrl.push(res.data.url);
      } catch (e) {}
    },
    // 删除文件
    onDeleteFileList(file) {
      this.designFileUrl = this.designFileUrl.filter((e) => e != file.url);
    },
    sumittwoForm(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        if (this.designFileUrl && this.designFileUrl.length <= 0) {
          this.$message.error("请上传领导签字审批资料");
          return;
        }
        this.twoform.pcAuditUrl = this.designFileUrl.toString()
        this.twoform.type = 7
        updateType(this.twoform).then(res=>{
          if(res.code===200){
            this.$message.success(res.msg)
            this.twodialogVisible = false
            this.loadListtwo()//计通审核
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    turnMenu(row){
      this.form.completionId = row.completionId
      this.dialogVisible = true
    },
    twoturnMenu(row){
      this.twoform.completionId = row.completionId
      this.twodialogVisible = true
    },
    loadList(){
      this.searchBox.type= 2
      storeClerkList(this.searchBox).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    loadListtwo(){
      this.searchBox.type= 2
      countsSeriesList(this.searchBox).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    sumit(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        if(this.hanleBtn==1){
          this.form.type = 4
        }else {
          this.form.type = 7
        }
        updateType(this.form).then(res=>{
          if(res.code===200){
            this.$message.success(res.msg)
            this.dialogVisible = false
            if(this.hanleBtn==1){
              this.loadList()//仓管审核
            }else {
              this.loadListtwo()//计通审核
            }
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    returnApplyInfo(row) {
      returnApplyInfo({ id: row.id }).then((res) => {
        this.project = res.data;
        this.returnMode = row.returnMode;
        this.detildialogVisible = true;
      });
    },
    closeFrom(form) {
      this.form.pcCheckMaterial = "";
      this.form.pcCheckDescribe = "";
      this.form.id = null;
      this.$refs[form].resetFields()
    },
    closetwoFrom(form) {
      this.fileList = [];
      this.designFileUrl = [];
      this.twoform = {
        pcAuditFunds:'',
        pcAuditCost:'',
        pcAuditProfit:'',
      }
      this.$refs[form].resetFields()
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList();
    },
  },
};
</script>

<style scoped>
  .requireTitle:before{
    content:"*";
    color:red;
    font-weight:bold;
    position: relative;
    top: 30px;
    left: 15px;
  }
</style>