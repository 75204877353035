<template>
  <div class="content">
  <div class="contentbox">
    <el-tabs   v-model="activeName">
      <el-tab-pane label="待确认" name="0">
        <ToBeConfirmed v-if="activeName==0" :hanleBtn="1"></ToBeConfirmed>
      </el-tab-pane>
      <el-tab-pane label="待审核" name="1">
        <ToAudit v-if="activeName==1" :hanleBtn="1"></ToAudit>
      </el-tab-pane>
      <el-tab-pane label="审核记录" name="2">
        <AuditRecords v-if="activeName==2" :hanleBtn="1"></AuditRecords>
      </el-tab-pane>
    </el-tabs>
  </div>
  </div>
</template>

<script>
import AuditRecords from './components/auditRecords'
import ToAudit from './components/toAudit'
import ToBeConfirmed from './components/toBeConfirmed'
export default {
  name: "dataMember",
  components:{ AuditRecords,ToAudit,ToBeConfirmed },
  data(){
    return{
      activeName:0
    }
  }
}
</script>

<style scoped>
.content{
  width: 100%;
  background: #f3f4f8;
}
.contentbox {
  margin: 10px;
  padding: 10px;
  background-color:#fff;
  position: relative;
}
</style>