<template>
  <div class="sonContent">
    <!--数据表格-->
    <el-table
        ref="tableData"
        :data="tableData"
        style="width: 100%;margin: 15px 0;"
        border
        height="calc(100vh - 328px)"
        :stripe="true">
      <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
      <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="constructionTeam" label="第三方施工队" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createBy" label="提交人" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" label="提交时间" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" align="left" show-overflow-tooltip>
        <template slot-scope="{row}">
          <el-button v-if="hanleBtn==1" type="success" size="small" @click="makeTrue(row,3)">确认收到</el-button>
          <el-button v-if="hanleBtn==2" type="success"  size="small" @click="makeTrue(row,6)">确认收到</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
          :current-page="searchBox.current"
          :background="true"
          :page-sizes="[ 30, 50, 100]"
          :page-size="searchBox.size"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>

</template>

<script>
import {storeClerkList,countsSeriesList,updateType} from "../../../RequestPort/cost/cost";
export default {
  name: "dataTabel",
  props:{
    hanleBtn:{
      type:[Number,String],
      default:null
    }
  },
  data(){
    return{
      searchBox:{
        current:1,
        size: 100,
        type:1
      },
      total:null,
      tableData:[]
    }
  },
  mounted() {
    if(this.hanleBtn==1){
      this.loadList()//仓管
    }else {
      this.loadListtwo()//计通
    }
  },
  methods:{
    makeTrue(row,type){
      this.$confirm(`确认【${row.projectName}】的工程量已收到？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        updateType({completionId:row.completionId,type:type}).then(res=>{
            if(res.code===200){
                this.$message.success(res.msg)
              if(this.hanleBtn==1){
                this.loadList()//仓管
              }else {
                this.loadListtwo()//计通
              }
            }else {
                this.$message.error(res.msg)
            }
        })
      }).catch(()=>{})
    },
    loadList(){
      storeClerkList(this.searchBox).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    loadListtwo(){
      countsSeriesList(this.searchBox).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      if(this.hanleBtn==1){
        this.loadList()//仓管
      }else {
        this.loadListtwo()//计通
      }
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      if(this.hanleBtn==1){
        this.loadList()//仓管
      }else {
        this.loadListtwo()//计通
      }
    },
  }
}
</script>

<style scoped>

</style>