//  引入拦截器
import axios from '@/common/js/request';

export default {
    //  待处理列表
    getDesignTableData(data) {
        return axios({
            url: '/designChange/selectDesignChangeList',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  正式设计列表
    getFormalDesignTableData(data) {
        return axios({
            url:'/design/selectDesignList',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data
        })
    },
    //  查询工程列表
    getEngineeringList() {
        return axios({
            url:'/design/selectDesignProjectList',
            method:'POST',
            headers:{
                'Content-Type': 'application/json'
            }
        })
    },
    //  上传图片
    uploadImg(data){
        return axios({
            url: '/aliYun/uploadGasPatrolImgToAliYunOSS',
            method: 'post',
            data
        })
    },
    uploadImg1(data){
        return axios({
            url: '/aliYun/uploadMarkImg',
            method: 'post',
            data
        })
    },
    //  上传设计
    onAddDesign(data) {
        return axios({
            url:'/design/saveDesign',
            method:'post',
            data
        })
    },
}